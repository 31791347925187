<template>
  <div v-loading="loaders.tags" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex">
      <h1 class="d-inline-block">Patient Tags</h1>
      <router-link class="ml-auto"
                   :to="{ name: 'CategoryForm',
                          params: { newFlag: true }}">
        <base-button class="btn-secondary-action px-5"
                     :class="{invisible: buttonLoader}"
        >Add New
        </base-button>
      </router-link>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.tags"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.tags"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.tags"
        >Deactivate
        </base-button>
      </div>
      <el-table
        ref="multipleTable"
        header-row-class-name="thead-light"
        :data="response.tags"
        @selection-change="alertsMultiSelect"
      >
        <el-table-column
          v-for="(column,index) in tableColumns"
          :key="column.label"
          v-bind="column"
        >
          <template v-if="index===2" scope="scope">
            <div v-if="scope.row.sub_category.length > 1">
              <span>
                {{ handleLongString(scope.row.sub_category[0]) }},
              </span>
              <el-popover
                placement="bottom-end"
                trigger="hover"
                :visible-arrow="false"
                width="fit-content"
              >
                <span slot="reference" class="tooltip">
                  +{{ scope.row.sub_category.length - 1 }} more
                </span>

                <template v-for="(item,index) in scope.row.sub_category">
                    <span class="tooltip-text" v-if="index!==0">
                      {{ item }}
                    </span>
                </template>

              </el-popover>

            </div>
            <div v-else>
              <span v-for="item in scope.row.sub_category">{{ handleLongString(item) }}</span>
            </div>
          </template>
          <template v-else-if="index===3" scope="scope">
              <span :class="[column.prop, getColumn(scope.row, column)]">
                <div class="d-inline-block indicator" :class="getColumn(scope.row, column)"></div>
                {{ getColumn(scope.row, column) }}
              </span>
          </template>
          <template v-else-if="index!==0" scope="scope">
            <span>{{ getColumn(scope.row, column) }}</span>
          </template>
        </el-table-column>
        <el-table-column v-bind="lastColumn">
          <div slot-scope="{ $index, row }" class="d-flex">
            <img
              class="pointer mr-2"
              @click="handleEdit($index, row)"
              src="/img/icons/buttons/edit.svg"
              alt="Edit"
            />
            <img
              class="pointer"
              @click="handleDelete($index, row)"
              src="/img/icons/buttons/delete.svg"
              alt="Delete"
            />
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :button-text="buttonText"
        :removeId="removeId"
        @onRemove="removeCategory"
        @onRemoveMultiple="deleteCategory"
      />
    </div>
  </div>
</template>

<script>
import {Option, Select, Table, TableColumn} from "element-ui";
import BackButton from "@/components/Router/BackButton";
import {BasePagination} from "@/components";
import TableWithActionsAndPagination from "@/components/Tables/TableWithActionsAndPagination";

const RemoveModal = () =>
  import(
    /* webpackChunkName: "nutritionAlerts" */ "@/components/Modals/RemoveModal.vue"
    );

export default {
  name: "UserCategoryList",
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CategoryTemplate: TableWithActionsAndPagination,
    BackButton,
    RemoveModal,
  },
  mounted() {
    this.fetchTags()
  },
  data() {
    return {
      buttonText: {
        success: 'Continue',
        defer: 'Cancel'
      },
      loaders: {
        tags: true,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        tags: [],
      },
      tableColumns: [
        {
          prop: "selected",
          type: 'selection',
          width: 50,
        },
        {
          prop: "name",
          label: "Tag Type",
          minWidth: 160,
        },
        {
          prop: "sub_category",
          label: "Tag Name",
          minWidth: 180,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 110,
        },
      ],
      lastColumn: {
        label: "Actions",
        minWidth: 80,
      },
    };
  },
  methods: {
    removeCategory(id) {
      this.deleteRequest(id)
    },
    deleteCategory() {
      this.deleteRequest(this.removeId)
    },
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },
    handleLongString(longString) {
      return (longString.length > 15) ? longString.slice(0, 15) + '...' : longString
    },
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },
    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'If you delete this patient tag, any patients marked with this tag will no longer have this tag.';
      this.removeId = row.id;
    },
    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'If you delete these patient tags, any patients marked with these tags will no longer have these tags.';
      this.removeId = this.extractSelectedIds;
    },
    fetchTags() {
      this.loaders.tags = true
      axios.get(this.$store.getters.getBaseUrl + '/api/user-category')
        .then((response) => this.response.tags = response.data.data)
        .catch((error) => console.error(error.response))
        .finally(() => this.loaders.tags = false)
    },
    getColumn(row, column) {
      if (column.prop === 'status') {
        return this.getStatus(row.status);
      } else if (column.prop === 'name') {
        return this.handleLongString(row.name)
      }
      return row[column.prop];
    },
    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },
    handleEdit(index, row) {
      this.$router.push({
        name: 'CategoryForm',
        params: {
          newFlag: false,
          category: row
        }
      })
    },
    handleDelete(index, row) {
      this.promptRemove(row)
    },
    alertsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },
    clearSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    deleteRequest(data) {
      this.loaders.tags = true
      this.loaders.checkboxButton.Delete = true
      const payload = {
        ids: Array.isArray(data) ? data : [data]
      }
      axios.post(this.$store.getters.getBaseUrl + `/api/user-category/delete`, payload)
        .then((response) => {
          this.removeId = []
          this.fetchTags()
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.checkboxButton.Delete = false
          this.loaders.tags = false
        })
    },
    statusUpdateRequest(ids, status) {
      this.loaders.tags = true
      if (status === true){
        this.loaders.checkboxButton.Activate = true
      }else {
        this.loaders.checkboxButton.DeActive = true
      }
      const payload = {
        ids: ids,
        status: status
      }
      axios.post(this.$store.getters.getBaseUrl + `/api/user-category/status-update`, payload)
        .then((response) => {
          if (status === true){
            this.loaders.checkboxButton.Activate = false
          }else {
            this.loaders.checkboxButton.DeActive = false
          }
          this.removeId = []
          this.fetchTags()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.tags = false
        })
    }
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
    buttonLoader() {
      return (this.response.tags.length >= 8) || (this.loaders.tags)
    }
  }
}
</script>

<style scoped>

.tooltip {
  display: inline-block;
  opacity: 1;
  color: #832C44;
  margin-top: 1px;
  padding-left: 5px;
  height: 18px;
  z-index: inherit;
}

.tooltip-text {
  display: block;
  font: normal normal 600 12px/20px Open Sans;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #000D18;
  background: #FFFFFF 0 0 no-repeat padding-box;
}

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

</style>
