<template>
  <div>
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="m-0 px-3 pt-4 pb-0 pr-2">
      <h1>{{ titleHeading }} Patient Tag</h1>
      <checklist-item-card
        ref="checklistItemCard"
        screen="category-form"
        :hide-tool-tip="true"
        :show-patient-check-icon="false"
        :items-list="categoryState.user_sub_categories"
        :show-headers="false"
        :actions="actions"
        :rules="rules.subcategory"
        :table-columns="tableColumns"
        v-loading="loaders.form"
        @addChecklistItemRow="addChecklistItem"
        @addToUpdateList="addToUpdateList"
        @handleDelete="handleDelete"
      >
        <template v-slot:header>
          <div>
            <el-form ref="categoryForm"
                     :rules="rules.category"
                     label-position="top"
                     :inline="true"
                     :model="categoryForm"
            >
              <label class="w-50 mb-3">Tag Type*</label>
              <label class="w-50 mb-3">Status*</label>

              <el-form-item
                style="max-width: 49%; width: 100%"
                prop="name"
              >
                <el-input
                  v-model="categoryForm.name"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="max-width: 49%; width: 100%"
                prop="status"
              >
                <el-select
                  placeholder="Active/Inactive"
                  v-model="categoryForm.status"
                  style="width: 100%"
                >
                  <el-option
                    v-for="option in options"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <label>Tag Name</label>
          </div>
        </template>
      </checklist-item-card>
      <base-button class="btn-primary-action py-2 px-5"
                   @click="submitForms"
                   :loading="loaders.form"
      >Save
      </base-button>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import ChecklistItemCard from "@/views/Components/Checklist/ChecklistItemCard";


export default {
  name: "CategoryForm",
  components: {
    ChecklistItemCard,
    BackButton
  },
  mounted() {
    if (this.newFlag == null) {
      this.$router.replace({name: 'UserCategoryList'})
    } else if (!this.newFlag) {
      this.categoryForm.status = !!(this.category.status)
      this.categoryForm.name = this.category.name
      this.categoryState = this.category
    }
  },
  props: {
    newFlag: {
      Boolean,
      Default: true
    },
    category: {}
  },
  data() {
    return {
      actions: [
        {
          src: '/img/icons/buttons/remove.svg',
          action: 'delete',
          minWidth: 80,
        }
      ],
      categoryForm: {
        name: '',
        status: null
      },
      categoryState: {
        user_sub_categories: []
      },
      loaders: {
        form: false,
      },
      options: [
        {
          label: 'Active',
          value: true
        },
        {
          label: 'Inactive',
          value: false
        },
      ],
      request: {
        list: [],
        toRemoveIds: [],
        toUpdate: [],
      },
      rules: {
        category: {
          name: {required: true, message: 'required', trigger: 'blur'},
          status: {required: true, message: 'required', trigger: 'blur'}
        },
        subcategory: {name: {required: true, message: 'required', trigger: 'blur'}},
      },
      tableColumns: [
        {
          prop: "name",
          label: "Sub Category",
          minWidth: 75,
        },
      ],
    }
  },
  methods: {
    submitForms() {
      let validForm = false;
      let errorCount = 0;
      this.$refs['categoryForm'].validate((valid) => {
        if (valid) {
          validForm = true
        } else {
          errorCount += 1;
          return false;
        }
      });
      for (let counter = 0; counter < this.categoryState.user_sub_categories.length; counter++) {
        this.$refs.checklistItemCard.$refs['checklistForm'][counter].validate((valid) => {
          if (!valid) {
            validForm = false;
            errorCount += 1;
            return false;
          }
        });
      }
      if (validForm) {
        if (this.newFlag) {
          this.createCategoryRequest()
        } else {
          this.editCategoryRequest()
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter the values',
        });
      }
    },
    addChecklistItem() {
      let checklistItem = {
        name: '',
      };
      this.categoryState.user_sub_categories.push(checklistItem);
      this.request.list.push(checklistItem);
    },
    handleDelete(event) {
      this.categoryState.user_sub_categories.splice(event.index, 1);
      this.request.list.splice(event.list, 1)
      if (event.element.hasOwnProperty("id")) {
        this.request.toRemoveIds.push(event.element.id);
      }
    },
    addToUpdateList(element) {
      if (element.hasOwnProperty("id")) {
        this.request.toUpdate.push(element);
      }
    },
    createCategoryRequest() {
      this.loaders.form = true
      const payload = {
        name: this.categoryForm.name,
        status: this.categoryForm.status,
        subCategories: this.extractNames
      };
      axios.post(this.$store.getters.getBaseUrl + `/api/user-category/create`, payload)
        .then((response) => {
          this.$notify.success({
            title: 'Success',
            message: 'Patient Tag Created Successfully.'
          });
          this.$router.replace({name: 'UserCategoryList'})
        }).catch((error) => {
        this.$notify.error({
          title: 'Failed to Create',
          message: error.message,
        });
      }).finally(() => this.loaders.form = false)
    },
    editCategoryRequest() {
      this.loaders.form = true
      const payload = {
        categoryName: this.categoryForm.name,
        categoryStatus: this.categoryForm.status,
        subCategoriesNew: this.extractNames,
        subCategoriesUpdate: this.request.toUpdate,
        subCategoriesRemove: this.request.toRemoveIds,
      }
      axios.post(this.$store.getters.getBaseUrl +
        `/api/user-category/update/${this.categoryState.id}`, payload)
        .catch((error) => {
          this.$notify.error({
            title: 'Failed to Update',
            message: error.message,
          });
        })
        .then(() => {
          this.$notify.success({
            title: 'Success',
            message: 'Patient Tag Updated Successfully.',
        })
          this.$router.go(-1)
        }).finally(() => this.loaders.form = false)
    }
  },
  computed: {
    titleHeading() {
      return (this.newFlag) ? 'Add ' : 'Edit '
    },
    extractNames() {
      let names = []
      for (let obj of this.request.list) {
        if (obj.name != null && obj.name !== '') names.push(obj.name)
      }
      return names
    }
  }
}
</script>

<style scoped>

label {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  opacity: 1;
}

</style>
